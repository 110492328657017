import React, { useContext } from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"

export const ATF_RichText = graphql`
  fragment ATF_RichText on PrismicCvswapoutContentPageDataBodyRichtext {
    id
    slice_type
    primary {
      content {
        html
        richText
      }
    }
  }
`

const CmsRichTextContent = ({ items, type }) => {
  let curentItem = items && items[0] ? items[0] : items ? items : null
  if (curentItem) {
    if (type === "HTML") {
      return <PrismicRichText field={curentItem.primary.content.richText} />
    } else {
      //return <div dangerouslySetInnerHTML={{ __html: curentItem.primary.content.html}}></div>
      return <PrismicRichText field={curentItem.primary.content.richText} />
    }
  }
}

const CmsRichText = ({ items, type }) => {
  return (
    <section className="cms-content--rich-text">
      <CmsRichTextContent items={items} type={type} />
    </section>
  )
}

export default CmsRichText
