import React from "react"
import CmsRichText from "./CmsContentType/CmsRichText"

import "./CmsContent.scss"

function CmsContentType({ contents, singletype }) {
  const CmsContentItems = contents.map((content, index) => {
    if (singletype && singletype !== content.slice_type) {
      return false
    } else {
      switch (content.slice_type) {
        case "richtext":
          return <CmsRichText items={content} key={index} />
        case "table_-_rich_text":
          return <CmsRichText items={content} key={index} type={"HTML"} />
        default:
          return <></>
      }
    }
  })

  return CmsContentItems
}

const CmsContent = ({ article, singletype }) => {
  return (
    <section className="cms-content">
      <div className="cms-content-types">
        <CmsContentType contents={article} singletype={singletype} />
      </div>
    </section>
  )
}

export default CmsContent
