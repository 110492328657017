import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/layout"
import Seo from "../components/global/Seo/seo"
import CmsContent from "./../components/CmsContent/CmsContent"
import "./../styles/sass/articles.scss"

export const query = graphql`
  query ContentPageQuery($uid: String!) {
    prismicCvswapoutContentPage(uid: { eq: $uid }) {
      id
      data {
        title
        seo_metadata {
          document {
            ...seoData
          }
        }
        body {
          ... on PrismicCvswapoutContentPageDataBodyRichtext {
            id
            primary {
              content {
                html
                richText
              }
            }
            slice_type
          }
          ... on PrismicCvswapoutContentPageDataBodyTableRichText {
            id
            slice_type
            primary {
              content {
                html
                richText
              }
            }
          }
        }
      }
    }
  }
`

const ContentPage = ({ data }) => {
  return (
    <Layout>
      <Seo
        data={data.prismicCvswapoutContentPage.data.seo_metadata.document.data}
      />
      <article className="article content-page static-page-width container-fluid">
        <div className="container article-header">
          <h1 className="h2">{data.prismicCvswapoutContentPage.data.title}</h1>
        </div>
        <section className="container article-body col">
          <CmsContent article={data.prismicCvswapoutContentPage.data.body} />
        </section>
      </article>
    </Layout>
  )
}

export default ContentPage
